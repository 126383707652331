var config = {};

config.general = {};
config.general.formats = {};
config.organisation = {};
config.drawing = {};
config.formats = {};

/*
    General
*/
config.general.timezone = "America/Moncton"; // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
config.general.locale = "fr";
config.general.formats.end_date_view = "yyyy-LL-dd";
config.general.formats.end_date_view_alt = "EEEE 'le' d MMM y";
config.general.formats.datetime_view = "yyyy-LL-dd HH:mm";
config.general.formats.datetime_view_alt = "EEEE 'le' d MMM y 'à' HH:mm";

/*
    Organisation
*/
config.organisation.permit_number = '019337356006'; // Number shown on receipt to clients
config.organisation.phone_number = '506 740-4807'; // Phone number shown on receipt to clients

/*
    Drawings
*/
config.drawing.day_of_week = 3; // 1=Sunday,2=Monday,etc.
config.drawing.week_time_cut_off = { hours:22 }; // Monday 9pm of the current week, Tuesday would be {days:1,hours:21}


/*
    Formats
*/
config.formats.date = '';
config.formats.time = '';
config.formats.date_time = '';

export default config;